import { createTheme } from "@mui/material";

export const fonts = {
  futura: "Futura, sans-serif",
  bodoni: "Bodoni Moda, serif",
};

export const theme = createTheme({
  typography: {
    fontFamily: fonts.bodoni,
    fontSize: 16,
    fontWeightRegular: 500,
    h1: {
      textTransform: "uppercase",
      fontFamily: fonts.futura,
    },
    h2: {
      textTransform: "uppercase",
      fontFamily: fonts.futura,
    },
    h3: {
      textTransform: "uppercase",
      fontFamily: fonts.futura,
    },
    h4: {
      textTransform: "uppercase",
      fontFamily: fonts.futura,
      fontSize: "36px",
      "@media (max-width:600px)": {
        fontSize: "30px",
      },
    },
    h5: {
      textTransform: "uppercase",
      fontFamily: fonts.futura,
    },
    h6: {
      textTransform: "uppercase",
      fontFamily: fonts.futura,
      "@media (max-width:600px)": {
        fontSize: "14px",
      },
    },
    button: {
      fontFamily: fonts.futura,
    },
  },
  palette: {
    primary: {
      main: "#92bbc0",
    },
    secondary: {
      main: "#ffffff",
    },
    text: {
      primary: "#565656",
    },
  },
});
