import { Box, colors, Stack, Typography } from "@mui/material";
import { Piece } from "../types";

type Props = {
  piece: Piece;
};

const Card = ({ piece }: Props) => {
  return (
    <>
      <img src={piece.imageSmall} alt={piece.title} style={{ width: "100%" }} />
      <Stack direction="row" gap={1} alignItems="center">
        <Typography>{piece.title}</Typography>
        {piece.sold && <Box width={10} height={10} borderRadius="100%" bgcolor={colors.red[800]}></Box>}
      </Stack>
      <Typography fontSize={14}>{piece.size}</Typography>
    </>
  );
};

export default Card;
