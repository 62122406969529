import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { fetchAllPieces } from "../services/firebase";
import ArtGrid from "../components/ArtGrid";
import { Artist, Piece } from "../types";

const Victoria = () => {
  const [pieces, setPieces] = useState<Piece[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPieces = async () => {
      setLoading(true);
      try {
        const result = await fetchAllPieces({ artist: Artist.Victoria });
        setPieces(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching pieces:", error);
      }
    };

    if (!pieces && !loading) {
      fetchPieces();
    }
  });

  return (
    <Box padding={{ xs: 2, md: 5 }}>
      <Stack gap={4}>
        <Typography variant="h4" component="h1" align="center">
          Victoria Orr Ewing
        </Typography>
        <Typography align="center" width={{ xs: "100%", md: "80%" }} margin="auto">
          Orr Ewing's empty wild places, often stark and haunting, carry a hint of ominous beauty. They are landscapes, sometimes
          adhering to reality, at other times favouring reduction to abstracted colour fields. A gloaming, liminal world where
          vividly real places affect us as much with absence as presence.
        </Typography>
        {pieces && <ArtGrid pieces={pieces} />}
      </Stack>
    </Box>
  );
};

export default Victoria;
