import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { fetchAllPieces } from "../services/firebase";
import ArtGrid from "../components/ArtGrid";
import { Artist, Piece } from "../types";

const Benji = () => {
  const [pieces, setPieces] = useState<Piece[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPieces = async () => {
      setLoading(true);
      try {
        const result = await fetchAllPieces({ artist: Artist.Benji });
        setPieces(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching pieces:", error);
      }
    };

    if (!pieces && !loading) {
      fetchPieces();
    }
  });

  return (
    <Box padding={{ xs: 2, md: 5 }}>
      <Stack gap={4}>
        <Typography variant="h4" component="h1" align="center">
          Benjamin Lowsley-Williams
        </Typography>
        <Typography align="center" width={{ xs: "100%", md: "80%" }} margin="auto">
          Lowsley Williams presents us with, rather than uses, stone. “The bones of the earth”, alchemically forged by heat and
          pressure over millions of years. Preferring raw stone or found pieces, his work extends and corresponds with the
          quarrying process, exploring the inherent properties of the stone itself. Reminiscent of ancient standing stones, they
          vertically bisect Orr Ewing's otherwise open landscapes.
        </Typography>
        {pieces && <ArtGrid pieces={pieces} />}
      </Stack>
    </Box>
  );
};

export default Benji;
