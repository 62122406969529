import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { editPiece, fetchPieceById } from "../../services/firebase";
import DragAndDrop from "../../components/DragAndDrop";
import { Piece } from "../../types";
import useImageDrop from "./useImageDrop";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

type Props = {
  editPieceModalOpen: boolean;
  onClose: () => void;
};

const EditModal = ({ editPieceModalOpen, onClose }: Props) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [pieceData, setPieceData] = useState<Partial<Piece>>();
  const { uploading, handleImageDrop } = useImageDrop({ pieceData, setPieceData });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleOnCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPieceData({
      ...pieceData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPieceData({
      ...pieceData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = ({ target }: SelectChangeEvent) => {
    setPieceData({
      ...pieceData,
      [target.name]: target.value,
    });
  };

  const onUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    try {
      if (!id) {
        setLoading(false);
        return setError("Missing ID");
      }
      if (
        !pieceData ||
        !pieceData.title ||
        !pieceData.artist ||
        !pieceData.imageLarge ||
        !pieceData.imageSmall ||
        !pieceData.size ||
        !pieceData.medium ||
        !pieceData.price
      ) {
        setLoading(false);
        return setError("Missing fields");
      }
      await editPiece(id, {
        title: pieceData.title,
        description: pieceData.description || "",
        artist: pieceData.artist,
        imageLarge: pieceData.imageLarge,
        imageSmall: pieceData.imageSmall,
        size: pieceData.size,
        medium: pieceData.medium,
        price: pieceData.price,
        sold: pieceData.sold || false,
      });
      onClose();
      setLoading(false);
      navigate("/admin");
    } catch (error) {
      setLoading(false);
      console.error("Error updating piece:", error);
      setError("Error updating piece");
    }
  };

  useEffect(() => {
    const getPiece = async () => {
      if (!id) return;
      try {
        const result = await fetchPieceById(id);
        setPieceData(result);
      } catch (error) {
        console.error("Error fetching piece:", error);
      }
    };

    if (id && !pieceData) {
      getPiece();
    }
  });

  return (
    <Modal open={editPieceModalOpen} onClose={onClose}>
      <Box sx={style}>
        {pieceData ? (
          <Stack gap={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit {pieceData.title}
            </Typography>
            <form onSubmit={onUpdate}>
              <Stack gap={2}>
                <TextField placeholder="Title" name="title" onChange={handleOnChange} defaultValue={pieceData.title} />
                <TextField
                  placeholder="Description"
                  name="description"
                  onChange={handleOnChange}
                  defaultValue={pieceData.description}
                />
                <TextField placeholder="Size" name="size" onChange={handleOnChange} defaultValue={pieceData.size} />
                <TextField placeholder="Medium" name="medium" onChange={handleOnChange} defaultValue={pieceData.medium} />
                <TextField placeholder="Price" name="price" onChange={handleOnChange} defaultValue={pieceData.price} />
                <Select placeholder="Artist" name="artist" onChange={handleSelectChange} defaultValue={pieceData.artist}>
                  <MenuItem value="victoria">Victoria</MenuItem>
                  <MenuItem value="benji">Benji</MenuItem>
                </Select>
                <FormControlLabel
                  control={<Checkbox name="sold" onChange={handleOnCheckboxChange} defaultChecked={pieceData.sold || false} />}
                  label="Sold"
                />
                {uploading ? (
                  <Typography>Uploading...</Typography>
                ) : (
                  <DragAndDrop onImageDrop={handleImageDrop} label="Drop image here" />
                )}
                <canvas id="canvas" style={{ display: "none" }} />
                {pieceData.imageSmall && <img src={pieceData.imageSmall} alt={pieceData.title} style={{ width: "100%" }} />}
                <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
                  Update Piece
                </LoadingButton>
                {error && <Typography color="error">{error}</Typography>}
              </Stack>
            </form>
          </Stack>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default EditModal;
