import { ChangeEvent, FormEvent, useState } from "react";
import { OutlinedInput, Stack, Typography } from "@mui/material";
import { authSignInUser } from "../services/firebase";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });
  const [data, setData] = useState({ email: "", password: "" });

  const onSubmit = async (event: FormEvent) => {
    setLoading(true);
    event.preventDefault();
    try {
      await authSignInUser(data, setError);
      setLoading(false);
      navigate("/admin");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={2} padding={2} width={{ xs: "100%", md: "60%" }} margin="auto">
        <OutlinedInput type="email" name="email" placeholder="Email" onChange={onChange} error={Boolean(error.email)} />
        {error.email && <Typography color="error">{error.email}</Typography>}
        <OutlinedInput
          type="password"
          name="password"
          placeholder="Password"
          onChange={onChange}
          error={Boolean(error.password)}
        />
        {error.password && <Typography color="error">{error.password}</Typography>}
        <LoadingButton type="submit" variant="contained" loading={loading}>
          Login
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default Login;
