import { useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Grid, Box, colors, IconButton, Stack, Typography, Button } from "@mui/material";
import { ArrowBackIosRounded, ArrowForwardIosRounded, CloseRounded } from "@mui/icons-material";
import { Piece } from "../types";
import Card from "./Card";

const artistEmails: { [artist: string]: string } = {
  victoria: "victoria@victoriaorrewing.com",
  benji: "benjlw@me.com",
};

const ArtGrid = ({ pieces }: { pieces: Piece[] }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const activePiece = pieces[activeIndex || 0];

  const openGalleryView = (index: number) => {
    document.body.style.overflow = "hidden";
    setActiveIndex(index);
  };

  const closeGalleryView = () => {
    document.body.style.overflow = "auto";
    setActiveIndex(null);
  };

  const prevPiece = () => {
    if (activeIndex !== null) {
      setActiveIndex((activeIndex - 1 + pieces.length) % pieces.length);
    }
  };

  const nextPiece = () => {
    if (activeIndex !== null) {
      setActiveIndex((activeIndex + 1) % pieces.length);
    }
  };

  const onEnquire = (artist: string) => {
    window.open(`mailto:${artistEmails[artist]}?subject=Enquiry about ${activePiece.title}`);
  };

  return (
    <>
      <Grid container spacing={2} rowSpacing={5}>
        {pieces?.map((piece, index) => (
          <Grid item key={piece.id} xs={12} md={4}>
            <Box onClick={() => openGalleryView(index)} sx={{ cursor: "pointer" }}>
              <Card piece={piece} />
            </Box>
          </Grid>
        ))}
      </Grid>
      {activeIndex !== null && (
        <Box
          position="fixed"
          top={0}
          left={0}
          height={{ xs: "calc(100vh - 48px)", md: "calc(100vh - 80px)" }}
          width={{ xs: "calc(100vw - 48px)", md: "calc(100vw - 128px)" }}
          paddingY={{ xs: 3, md: 5 }}
          paddingX={{ xs: 3, md: 8 }}
          bgcolor="#f5f5f5"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Stack direction={{ xs: "column", md: "row" }} gap={5} alignItems="center" width="100%" height="100%">
            <Box width={{ xs: "100%", md: "30%" }}>
              <Stack gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant="h5" component="h5">
                    {activePiece.title}
                  </Typography>
                  {activePiece.sold && <Box width={10} height={10} borderRadius="100%" bgcolor={colors.red[800]}></Box>}
                </Stack>
                <div>
                  {activePiece.description && <Typography>{activePiece.description}</Typography>}
                  <Typography>{activePiece.size}</Typography>
                  <Typography>{activePiece.medium}</Typography>
                  <Typography>{activePiece.price}</Typography>
                </div>
                <Button variant="contained" onClick={() => onEnquire(activePiece.artist)}>
                  Enquire
                </Button>
              </Stack>
            </Box>
            <Stack
              width={{ xs: "100%", md: "70%" }}
              height={{ xs: "auto", md: "100%" }}
              alignItems="center"
              justifyContent="center"
              sx={{ "& div": { display: "flex", alignItems: "center", height: "100%" } }}
            >
              <Zoom>
                <img src={activePiece.imageLarge} alt={activePiece.title} style={{ maxWidth: "100%", maxHeight: "100%" }} />
              </Zoom>
            </Stack>
            <IconButton sx={{ position: "absolute", top: 10, right: 10 }}>
              <CloseRounded onClick={closeGalleryView} />
            </IconButton>
            <IconButton sx={{ position: "absolute", top: "50%", left: 10 }}>
              <ArrowBackIosRounded onClick={prevPiece} />
            </IconButton>
            <IconButton sx={{ position: "absolute", top: "50%", right: 10 }}>
              <ArrowForwardIosRounded onClick={nextPiece} />
            </IconButton>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default ArtGrid;
