import { ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, colors, Stack, Typography } from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";

type Props = {
  onImageDrop: (files: File[]) => void;
  label?: string | ReactNode;
  error?: string;
};

const DragAndDrop = ({ onImageDrop, label, error }: Props) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      onImageDrop(acceptedFiles);
    },
    [onImageDrop]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box
      {...getRootProps()}
      bgcolor={error ? "error" : colors.grey}
      border="1px solid error"
      p={3}
      sx={{ cursor: "pointer" }}
      borderRadius={8}
    >
      <Stack alignItems="center" justifyContent="center" width="100%">
        <input {...getInputProps()} />
        <FileUploadOutlined />
        {error ? (
          <Typography align="center" margin="16px 0 0">
            {error}
          </Typography>
        ) : isDragActive ? (
          <Typography margin="16px 0 0">Drop the files here ...</Typography>
        ) : typeof label === "string" ? (
          <Typography margin="16px 0 0">{label}</Typography>
        ) : (
          label
        )}
      </Stack>
    </Box>
  );
};

export default DragAndDrop;
