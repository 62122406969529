import { useState } from "react";
import { uploadImage } from "../../services/firebase";
import { Piece } from "../../types";

const useImageDrop = ({
  pieceData,
  setPieceData,
}: {
  pieceData?: Partial<Piece>;
  setPieceData: (data: Partial<Piece>) => void;
}) => {
  const [uploading, setUploading] = useState(false);

  const handleImageDrop = async (files: File[]) => {
    setUploading(true);
    if (!files.length) return setUploading(false);
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = URL.createObjectURL(files[0]);
    image.onload = () => {
      const landscape = image.width > image.height;
      const ratio = landscape ? image.width / image.height : image.height / image.width;
      const reducedWidth = landscape ? 1000 * ratio : 1000;
      const reducedHeight = landscape ? 1000 : 1000 * ratio;
      canvas.width = reducedWidth;
      canvas.height = reducedHeight;
      ctx?.drawImage(image, 0, 0, reducedWidth, reducedHeight);
      canvas.toBlob(async (blob) => {
        if (!blob) return;
        const imageSmall = await uploadImage(`small-${files[0].name}`, blob);
        const imageLarge = await uploadImage(`large-${files[0].name}`, files[0]);
        console.log("Images uploaded", imageSmall, imageLarge);
        setPieceData({
          ...pieceData,
          imageSmall,
          imageLarge,
        });
        setUploading(false);
      });
    };
  };

  return {
    uploading,
    handleImageDrop,
  };
};

export default useImageDrop;
