import { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { addNewPiece, getNextPosition } from "../../services/firebase";
import DragAndDrop from "../../components/DragAndDrop";
import { Piece } from "../../types";
import useImageDrop from "./useImageDrop";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

type Props = {
  addPieceModalOpen: boolean;
  onClose: () => void;
};

const AddModal = ({ addPieceModalOpen, onClose }: Props) => {
  const [pieceData, setPieceData] = useState<Partial<Piece>>({});
  const { uploading, handleImageDrop } = useImageDrop({ pieceData, setPieceData });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPieceData({
      ...pieceData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = ({ target }: SelectChangeEvent) => {
    setPieceData({
      ...pieceData,
      [target.name]: target.value,
    });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    try {
      if (
        !pieceData.title ||
        !pieceData.artist ||
        !pieceData.imageLarge ||
        !pieceData.imageSmall ||
        !pieceData.size ||
        !pieceData.medium ||
        !pieceData.price
      ) {
        setLoading(false);
        return setError("Missing fields");
      }
      const position = await getNextPosition(pieceData.artist);
      await addNewPiece({
        title: pieceData.title,
        description: pieceData.description || "",
        artist: pieceData.artist,
        imageLarge: pieceData.imageLarge,
        imageSmall: pieceData.imageSmall,
        size: pieceData.size,
        medium: pieceData.medium,
        price: pieceData.price,
        sold: pieceData.sold || false,
        position,
      });
      onClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error adding piece:", error);
      setError("Error adding piece");
    }
  };

  return (
    <Modal open={addPieceModalOpen} onClose={onClose}>
      <Box sx={style}>
        <Stack gap={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add a new piece
          </Typography>
          <form onSubmit={onSubmit}>
            <Stack gap={2}>
              <TextField placeholder="Title" name="title" onChange={handleOnChange} />
              <TextField placeholder="Description" name="description" onChange={handleOnChange} />
              <TextField placeholder="Size" name="size" onChange={handleOnChange} />
              <TextField placeholder="Medium" name="medium" onChange={handleOnChange} />
              <TextField placeholder="Price" name="price" onChange={handleOnChange} />
              <Select placeholder="Artist" name="artist" onChange={handleSelectChange}>
                <MenuItem value="victoria">Victoria</MenuItem>
                <MenuItem value="benji">Benji</MenuItem>
              </Select>
              <FormControlLabel control={<Checkbox name="sold" onChange={handleOnChange} />} label="Sold" />
              {uploading ? (
                <Typography>Uploading...</Typography>
              ) : (
                <DragAndDrop onImageDrop={handleImageDrop} label="Drop image here" />
              )}
              <canvas id="canvas" style={{ display: "none" }} />
              {pieceData.imageSmall && <img src={pieceData.imageSmall} alt={pieceData.title} style={{ width: "100%" }} />}
              <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
                Add Piece
              </LoadingButton>
              {error && <Typography color="error">{error}</Typography>}
            </Stack>
          </form>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddModal;
