import { Link, Route, Routes } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Home from "./pages/Home";
import Victoria from "./pages/Victoria";
import Benji from "./pages/Benji";
import Admin from "./pages/Admin";
import "./App.css";
import Login from "./pages/Login";
import AuthGuard from "./components/AuthGuard";
import { fonts } from "./theme";

const navLinks = [
  { to: "/benji", label: "Benjamin Lowsley-Williams" },
  { to: "/", label: "Home" },
  { to: "/victoria-orr-ewing", label: "Victoria Orr Ewing" },
];

const alignment: { [index: number]: "left" | "center" | "right" } = {
  0: "left",
  1: "center",
  2: "right",
};

const App = () => {
  const isMobile = window.innerWidth < 750;

  return (
    <div className="App">
      <nav>
        <Stack
          component="ul"
          direction={{ xs: "column", md: "row" }}
          paddingX={{ xs: 2, md: 5 }}
          paddingY={2}
          margin={0}
          justifyContent="space-between"
          gap={{ xs: 1, md: 3 }}
          style={{ listStyle: "none" }}
        >
          {navLinks.map((link, index) => (
            <li key={link.to} style={{ flex: 1 }}>
              <Link to={link.to}>
                <Typography
                  align={isMobile ? "left" : alignment[index]}
                  fontFamily={fonts.futura}
                  textTransform="uppercase"
                  fontSize={{ xs: 14, md: 18 }}
                >
                  {link.label}
                </Typography>
              </Link>
            </li>
          ))}
        </Stack>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/victoria-orr-ewing" element={<Victoria />} />
        <Route path="/benji" element={<Benji />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AuthGuard element={Admin} />} />
        <Route path="/admin/:id" element={<AuthGuard element={Admin} />} />
      </Routes>
    </div>
  );
};

export default App;
