export type Piece = {
  id: string;
  title: string;
  description: string;
  medium: string;
  size: string;
  price: string;
  imageLarge: string;
  imageSmall: string;
  artist: "victoria" | "benji";
  sold: boolean;
  position: number;
};

export enum Artist {
  Victoria = "victoria",
  Benji = "benji",
}
