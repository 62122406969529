import { Box, Stack, Typography } from "@mui/material";
import benjihome from "../assets/benjihome.png";
import vichome from "../assets/vichome.png";
import vicProfile from "../assets/vic-profile.jpg";
import benjiProfile from "../assets/benji-profile.jpg";

const Home = () => {
  return (
    <Box padding={{ xs: 2, md: 5 }}>
      <Stack direction="row" gap={{ xs: 1, md: 3 }}>
        <Box flexBasis="50%" position="relative">
          <Stack position="absolute" top={{ xs: 10, md: 20 }} right={{ xs: 15, md: 30 }} gap={2}>
            <Typography variant="h3" component="h1" align="right" fontSize={{ xs: 24, md: 48 }} color="white">
              Intimate
            </Typography>
            <Typography variant="h5" align="right" fontSize={{ xs: 14, md: 32 }} color="white">
              Benjamin Lowsley-Williams
            </Typography>
          </Stack>
          <img src={benjihome} alt="Intimate Immensity" width="100%" height="100%" style={{ objectFit: "cover" }} />
        </Box>
        <Box flexBasis="50%" position="relative">
          <Stack position="absolute" top={{ xs: 10, md: 20 }} left={{ xs: 15, md: 30 }} gap={2}>
            <Typography variant="h3" component="h1" align="left" fontSize={{ xs: 24, md: 48 }} color="white">
              Immensity
            </Typography>
            <Typography variant="h5" align="left" fontSize={{ xs: 14, md: 32 }} color="white">
              Victoria Orr Ewing
            </Typography>
          </Stack>
          <img src={vichome} alt="Intimate Immensity" width="100%" height="100%" style={{ objectFit: "cover" }} />
        </Box>
      </Stack>

      <Stack gap={4} paddingTop={5}>
        <Typography variant="h6" align="center">
          The exhibition will be live on this website at the beginning of October
        </Typography>
        <Stack gap={1}>
          <Typography variant="h6" align="center">
            11 Avenue Studios, Sydney Mews, London, SW3 6HL
          </Typography>
          <Typography variant="h6" align="center">
            11th - 18th October 2024, 11am - 6pm
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={4} paddingY={5}>
        <Typography align="center">
          Victoria Orr Ewing and Benjamin Lowsley-Williams' work is “of the land” and the earth itself. It immerses us
          symbolically, conceptually and physically. The works are timeless - of the here and now or hinting at a distant and
          mythological past. Both artists' work evokes a sense of deep time. The geological and archaeological roll in with the
          mists and weather, leaving us with a sense of numinous mystery.
        </Typography>
        <Typography align="center">
          Lowsley Williams presents us with, rather than uses, stone. “The bones of the earth”, alchemically forged by heat and
          pressure over millions of years. Preferring raw stone or found pieces, his work extends and corresponds with the
          quarrying process, exploring the inherent properties of the stone itself. Reminiscent of ancient standing stones, they
          vertically bisect Orr Ewing's otherwise open landscapes.
        </Typography>
        <Typography align="center">
          Orr Ewing's empty wild places, often stark and haunting, carry a hint of ominous beauty. They are landscapes, sometimes
          adhering to reality, at other times favouring reduction to abstracted colour fields. A gloaming, liminal world where
          vividly real places affect us as much with absence as presence. Matched by the rawness and abstraction in Lowsley
          Williams' work, this show brings us inside to the intimate to experience the immensity of nature.
        </Typography>
      </Stack>
      <Stack direction="row" gap={{ xs: 1, md: 3 }}>
        <Box flexBasis="50%" position="relative">
          <img src={benjiProfile} alt="Benji Profile" width="100%" height="100%" style={{ objectFit: "cover" }} />
        </Box>
        <Box flexBasis="50%" position="relative">
          <img src={vicProfile} alt="Victoria Profile" width="100%" height="100%" style={{ objectFit: "cover" }} />
        </Box>
      </Stack>
    </Box>
  );
};

export default Home;
