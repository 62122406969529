import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
  query,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { Piece } from "../types";

const firebaseConfig = {
  apiKey: "AIzaSyBaSRU2rQwjmKQdwsABT9Tn-pAyaK3izh0",
  authDomain: "vic-exhibition.firebaseapp.com",
  projectId: "vic-exhibition",
  storageBucket: "vic-exhibition.appspot.com",
  messagingSenderId: "820622940887",
  appId: "1:820622940887:web:320b0605bba362d8032e92",
  measurementId: "G-K7W0XFS6GK",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

const fetchAllPieces = async (filters?: { [key: string]: any }): Promise<Piece[]> => {
  try {
    const customWhere = Object.entries(filters || {}).map(([key, value]) => where(key, "==", value));
    const piecesCollection = collection(db, "pieces");
    const piecesQueryResult = query(piecesCollection, ...customWhere, orderBy("position", "asc"));
    const piecesSnapshot = await getDocs(piecesQueryResult);
    const pieces: Piece[] = [];
    piecesSnapshot.forEach((doc) => {
      pieces.push({ id: doc.id, ...doc.data() } as Piece);
    });
    return pieces;
  } catch (error) {
    console.error("Error fetching pieces:", error);
    throw new Error("Error fetching pieces");
  }
};

const fetchPieceById = async (pieceId: string): Promise<Piece> => {
  try {
    const pieceDocRef = doc(db, "pieces", pieceId);
    const pieceDoc = await getDoc(pieceDocRef);
    if (!pieceDoc.exists()) {
      throw new Error(`No piece found with ID: ${pieceId}`);
    }
    return { id: pieceDoc.id, ...pieceDoc.data() } as Piece;
  } catch (error) {
    console.error(`Error fetching piece with ID ${pieceId}:`, error);
    throw new Error(`Error fetching piece with ID ${pieceId}`);
  }
};

const getNextPosition = async (artist: string): Promise<number> => {
  try {
    const pieces = await fetchAllPieces({ artist });
    const positions = [0, ...pieces.map((piece) => piece.position)];
    return Math.max(...positions) + 1;
  } catch (error) {
    console.error("Error fetching pieces:", error);
    throw new Error("Error fetching pieces");
  }
};

const updatePositions = async ({
  artist,
  pieceId,
  targetIndex,
}: {
  artist: string;
  pieceId: string;
  targetIndex: number;
}): Promise<void> => {
  try {
    const pieces = await fetchAllPieces({ artist });
    const piece = pieces.find((piece) => piece.id === pieceId);
    if (!piece) throw new Error("Piece not found");
    const updatedPieces = pieces.filter((piece) => piece.id !== pieceId);
    updatedPieces.splice(targetIndex, 0, piece);
    updatedPieces.forEach(async (piece, index) => {
      await editPiece(piece.id, { position: index });
    });
  } catch (error) {
    console.error("Error updating positions:", error);
    throw new Error("Error updating positions");
  }
};

const uploadImage = async (fileName: string, imageBlob: Blob) => {
  const storageRef = ref(storage, "images/" + fileName);
  const snapshot = await uploadBytes(storageRef, imageBlob);
  console.log("Uploaded a blob or file!", snapshot);
  const downloadURL = await getDownloadURL(snapshot.ref);
  console.log("File available at", downloadURL);
  return downloadURL;
};

const addNewPiece = async (piece: Omit<Piece, "id">): Promise<void> => {
  try {
    const piecesCollection = collection(db, "pieces");
    await addDoc(piecesCollection, piece);
  } catch (error) {
    console.error("Error adding new piece:", error);
    throw new Error("Error adding new piece");
  }
};

const editPiece = async (pieceId: string, piece: Partial<Piece>): Promise<void> => {
  try {
    const pieceDocRef = doc(db, "pieces", pieceId);
    await updateDoc(pieceDocRef, piece);
  } catch (error) {
    console.error("Error editing piece:", error);
    throw new Error("Error editing piece");
  }
};

const deletePiece = async (pieceId: string): Promise<void> => {
  try {
    const pieceDocRef = doc(db, "pieces", pieceId);
    await deleteDoc(pieceDocRef);
  } catch (error) {
    console.error("Error deleting piece:", error);
    throw new Error("Error deleting piece");
  }
};

const authStateChange = (action: (user: any | null) => void) => {
  auth.onAuthStateChanged((user) => action(user));
};

const authSignInUser = async (userData: { email: string; password: string }, setError: (error: any) => void) => {
  const { email, password } = userData;
  if (email && password) {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      const errorCode = (error as any).code;
      const errorMessage = (error as any).message;
      const message = errorMessage.split("Firebase: ");
      const inputError = errorMessage.includes("email") ? "email" : "password";
      setError({ [inputError]: message.length > 1 ? message[1] : message[0] });
      console.log(errorCode, ": ", errorMessage);
      throw error;
    }
  }
};

export {
  fetchAllPieces,
  fetchPieceById,
  getNextPosition,
  updatePositions,
  addNewPiece,
  editPiece,
  deletePiece,
  uploadImage,
  authStateChange,
  authSignInUser,
};
