import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { deletePiece, fetchAllPieces, updatePositions } from "../../services/firebase";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import { Artist, Piece } from "../../types";
import { DataGridPro, GridRowOrderChangeParams } from "@mui/x-data-grid-pro";

const Admin = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [addPieceModalOpen, setAddPieceModalOpen] = useState(Boolean(id));
  const [editPieceModalOpen, setEditPieceModalOpen] = useState(false);
  const [victoriaPieces, setVictoriaPieces] = useState<Piece[]>();
  const [benjiPieces, setBenjiPieces] = useState<Piece[]>();
  const [loading, setLoading] = useState(false);

  const fetchVictoriaPieces = async () => {
    try {
      const result = await fetchAllPieces({ artist: Artist.Victoria });
      setVictoriaPieces(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching pieces:", error);
    }
  };

  const fetchBenjiPieces = async () => {
    try {
      const result = await fetchAllPieces({ artist: Artist.Benji });
      console.log(result);
      setBenjiPieces(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching pieces:", error);
    }
  };

  const fetchPieces = async () => {
    setLoading(true);
    await fetchVictoriaPieces();
    await fetchBenjiPieces();
    setLoading(false);
  };

  const onDelete = async (pieceId: string) => {
    await deletePiece(pieceId);
    await fetchPieces();
  };

  const onAddModalClose = () => {
    setAddPieceModalOpen(false);
    fetchPieces();
  };

  const onEditModalClose = () => {
    setEditPieceModalOpen(false);
    fetchPieces();
    navigate("/admin");
  };

  const handleRowOrderChange = async (rowOrderChange: GridRowOrderChangeParams, artist: string) => {
    setLoading(true);
    await updatePositions({ artist, pieceId: rowOrderChange.row.id, targetIndex: rowOrderChange.targetIndex });
    await fetchPieces();
    setLoading(false);
  };

  const getRows = (pieces: Piece[]) => {
    return pieces.map((piece) => ({
      id: piece.id,
      title: piece.title,
      imageSmall: piece.imageSmall,
      price: piece.price,
      description: piece.description,
      medium: piece.medium,
      size: piece.size,
    }));
  };

  useEffect(() => {
    if (!victoriaPieces && !benjiPieces && !loading) {
      fetchPieces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEditPieceModalOpen(Boolean(id));
  }, [id]);

  const columns = [
    { field: "title", headerName: "Title", width: 150 },
    {
      field: "imageSmall",
      headerName: "Image",
      width: 150,
      renderCell: (params: any) => (
        <img src={params.value} alt={params.row.title} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
      ),
    },
    { field: "price", headerName: "Price", width: 150 },
    { field: "description", headerName: "Description", width: 150 },
    { field: "medium", headerName: "Medium", width: 150 },
    { field: "size", headerName: "Size", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params: any) => (
        <Grid container spacing={1}>
          <Grid item>
            <IconButton
              onClick={() => {
                navigate(`/admin/${params.row.id}`);
              }}
            >
              <Edit />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onDelete(params.row.id)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ),
    },
  ];

  const data = {
    pageSize: 10,
    pagination: true,
    rowHeight: 100,
    rowReorder: true,
  };

  return (
    <Box padding={5}>
      <Typography variant="h4" component="h4" align="center">
        Admin
      </Typography>
      <Stack gap={6} alignItems="start">
        <Button variant="contained" color="primary" onClick={() => setAddPieceModalOpen(true)}>
          Add New Piece
        </Button>

        <Stack gap={3} alignItems="start" width="100%">
          <Typography variant="h4">Victoria Orr Ewing</Typography>
          <Box width="100%" height="70vh">
            <DataGridPro
              {...data}
              columns={columns}
              rows={getRows(victoriaPieces || [])}
              loading={loading}
              rowReordering
              onRowOrderChange={(rowOrderChange) => handleRowOrderChange(rowOrderChange, Artist.Victoria)}
            />
          </Box>
        </Stack>

        <Stack gap={3} alignItems="start">
          <Typography variant="h4">Benjamin Lowsley-Williams</Typography>
          <Box width="100%" height="70vh">
            <DataGridPro
              {...data}
              columns={columns}
              rows={getRows(benjiPieces || [])}
              loading={loading}
              rowReordering
              onRowOrderChange={(rowOrderChange) => handleRowOrderChange(rowOrderChange, Artist.Benji)}
            />
          </Box>
        </Stack>

        <AddModal addPieceModalOpen={addPieceModalOpen} onClose={onAddModalClose} />
        <EditModal editPieceModalOpen={editPieceModalOpen} onClose={onEditModalClose} />
      </Stack>
    </Box>
  );
};

export default Admin;
