import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { authStateChange } from "../services/firebase";

type Props = {
  element: () => JSX.Element | null;
};

const AuthGuard = ({ element: Element }: Props) => {
  const [authorised, setAuthorised] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    authStateChange(async (authUser) => {
      setLoading(true);
      if (authUser) {
        const { uid } = authUser;
        setAuthorised(uid === process.env.REACT_APP_ADMIN_UID);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return authorised || loading ? <Element /> : <Navigate to="/login" replace />;
};

export default AuthGuard;
